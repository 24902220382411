import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

const StyledHomeText = styled.div`
    h2 {
        font-size: 28px;
        margin: 64px 0px 8px;
        line-height: 132.5%;
        font-family: 'noto serif';
        color: ${colors.blueMedium};
    }
    h2 + p {
        color: ${colors.grey};
        font-family: 'Lato';
        font-style: normal;
        margin: 0em;
        font-weight: 600;
        font-size: 24px;
        line-height: 132.5%;
    }
    /* main paragraph of text */
    p:last-of-type {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: ${colors.offBlack};
        span {
            font-weight: 800;
            color: ${colors.blueMedium};
        }
    }
    > div {
        margin-top: 8px;
        border: 1px solid #00bbff;
    }
    @media ${device.tablet} {
        h2 {
            font-size: 28px;
            margin: 128px 0px 8px;
        }
        > div {
            margin-top: 20px;
            max-width: 470px;
        }
    }
    @media ${device.laptopS} {
        h2 {
            font-size: 36px;
        }
        > div {
            margin-top: 20px;
            max-width: 470px;
        }
    }
    @media ${device.laptop} {
        p:last-of-type {
            max-width: 502px;
        }
    }

    @media ${device.laptopL} {
        h2 {
            font-size: 40px;
        }
        h2 + p {
            font-size: 32px;
            line-height: 132.5%;
        }
        p:last-of-type {
            max-width: 522px;
            font-weight: 400;
            font-size: 18px;
            line-height: 152.5%;
        }
    }
`;
export const HomeText: React.FC = ({ children }) => {
    return <StyledHomeText>{children}</StyledHomeText>;
};
